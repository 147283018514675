<template>
  <div v-if="error">{{error}}</div>
  
  <div v-if="loading && smallbanner_loading" class="container-fluid">
    <!-- This is carousel hero banner -->
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <div v-for="(hero,index) in hero_src" :key="hero">
          <div v-if="index == 0">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              class="active"
              aria-current="true"
            ></button>
          </div>
          <div v-else>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
            ></button>
          </div>
        </div>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(hero,index) in hero_src" :class="{active:index==0}" :key="hero"> 
          <a :href="hero[1]"><img :src="hero[0]" class="d-block w-100"/></a>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <!-- This is small banner -->
    <div class="sm_banner">
      <div v-if="windowSize<=936">
        <Carousel :autoplay="4000" :wrap-around="true" class="sm_banner_layout" :itemsToShow="1" :snapAlign="center">
          <Slide v-for="small in small_src" :key="small">
            <div class="carousel__item"> <a :href="small[1]"><img :src="small[0]"></a></div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>

      <div v-else-if="windowSize>936 && windowSize<=1400">
        <Carousel :autoplay="4000" :wrap-around="true" class="sm_banner_layout" :itemsToShow="2" :snapAlign="center">
          <Slide v-for="small in small_src" :key="small">
            <div class="carousel__item"> <a :href="small[1]"><img :src="small[0]"></a></div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>

       <div v-else-if="windowSize>1400 && windowSize<=1800">
        <Carousel :autoplay="4000" :wrap-around="true" class="sm_banner_layout" :itemsToShow="3" :snapAlign="center">
          <Slide v-for="small in small_src" :key="small">
            <div class="carousel__item"> <a :href="small[1]"><img :src="small[0]"></a></div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>

      <div v-else>
        <Carousel :autoplay="4000" :wrap-around="true" class="sm_banner_layout" :itemsToShow="4" :snapAlign="center">
          <Slide v-for="small in small_src" :key="small">
            <div class="carousel__item"> <a :href="small[1]"><img :src="small[0]"></a></div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
       
    </div>
   

    <!-- <div v-for="small in small_src" :key="small">
      <div id="small_banner">
        <div v-for="small in small_src_display" :key="small">
          <a :href="small[1]"><img :src="small[0]"></a>
        </div>
      </div>
    </div> -->

    <!-- Reseller -->
    <div
      id="reseller"
      class="section"
      :style="'background-image: url(' + reseller_src + ');'"
    >
      <div class="section-container">
        <h1>
          Accelerate Your Business Growth With Ma Labs
          <i class="fa fa-chevron-down"></i>
        </h1>

        <div id="reseller_cards">
          <div class="flex">
            <div class="card">
              <h2>Premium IT&nbsp;Distributor</h2>
              <p>
                With headquarters in Silicon Valley and multiple strategic hubs,
                our innovative logistical strategies allow us to rapidly deliver
                IT components anywhere.
              </p>
            </div>
            <div class="card">
              <h2>Partnership</h2>
              <p>
                By building trust-based relationships with vendors and clients,
                we anticipate your technical needs and accelerate your progress
                in achieving results.
              </p>
            </div>
            <div class="card">
              <h2>Services</h2>
              <p>
                Our outstanding account managers, technical support, and
                customer service professionals ensure an experience of
                unparalleled quality every time.
              </p>
            </div>
          </div>
        </div>

        <router-link class="btn btn_round" :to="{name: 'BecomeReseller'}">Become a reseller</router-link>
      </div>
    </div>

    <!-- Featured Stores -->
    <div id="featured_stores" class="section">
      <h1>Featured Stores <i class="fa fa-chevron-down"></i></h1>
      <div>
        <router-link
          v-for="item in featured_store"
          :key="item.brand"
          :to="'/partners/microsite/' + item.microsite_folder"
        >
          <img v-if="item.image" :src="item.image.meta.download_url" />
        </router-link>
      </div>
    </div>

    <!-- Newsletter -->
    <div
      id="newsletter"
      :style="'background-image: url(' + footer_src[0] + ');'"
    >
      <h1>Sign up for the Ma Labs Update Newsletter</h1>
      <p>
        Subscribe to our newsletter to get more industry updates and exclusive
        offers
      </p>
      <router-link class="btn btn_round" :to="{name: 'EmailNewsletters'}">Subscribe Today</router-link>
    </div>

    <!-- need to add one more banner not finished yet -->
    <!-- <div id="footer_banner">
      <router-link :to="{ name: 'EmailNewsletters'}"><img :src="footer_src[0]"/></router-link>
    </div> -->

    <!-- Eblast -->
    <!-- <div id="eblast" class="section">
      <img src="@/assets/images/eblast_icon.png"><span>Subscribe to our newsletter to get more industry updates and exclusive offers</span>
      <button class="btn">Subscribe today</button>
    </div> -->

    <!-- Promotions  -->
    <!-- <div id="promotions" class="section">
      <div class="section-container">
        <h1>Promotions <i class="fa fa-chevron-down"></i></h1>

        <div class="card">
          <img src="@/assets/images/Instant-Rebates.png">
          <h2>Instant Rebates</h2>
          <button class="btn">See All Instant Rebates</button>
        </div>
        <div class="card">
          <img src="@/assets/images/Mail-In-Rebates.png">
          <h2>Mail In Rebates</h2>
          <button class="btn">See All Mail-in Rebates</button>
        </div>
        <div class="card">
          <img src="@/assets/images/Monthly-Specials.png">
          <h2>Product Highlights</h2>
          <button class="btn">See All Product Highlights</button>
        </div>
      </div>
    </div> -->

    <!-- History -->
    <!-- <div id="history">
      <p>
        Ma Labs has been recognized as a preeminent distributor of computer components and computing solution provider since 1983
      </p>
      <button class="btn">Find Out More</button>
    </div> -->
  </div>
</template>

<script>
import { ref,onMounted,onUpdated} from "vue";
import { getAPI } from "../utils/axios-api";
// import Spinner from "../components/Spinner.vue";
import moment from "moment";
import $ from 'jquery'

//npm i vue3-carousel@0.1.34

import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';


export default {
  name: "Home",
  components: {Carousel,Slide,Navigation},
  setup() {
    let hero_url = "/content/snippets/adbanner/homepage/?location=Hero Banner";
    let small_url =
      "/content/snippets/adbanner/homepage/?location=Small Banner";
    let footer_url =
      "/content/snippets/adbanner/homepage/?location=Footer Banner";
    let reseller_url =
      "/content/snippets/adbanner/homepage/?location=Other Banner";
    let featured_store_url = "/content/snippets/featuredstore/";

    const hero_banner = ref({});
    const small_banner = ref({});
    const footer_banner = ref({});
    const featured_store = ref(null);
    const hero_src = ref({});
    // const hero_src_display = ref({});
    const small_src_display = ref([])
    const small_src = ref([]);
    const footer_src = ref([]);
    const reseller_src = ref(null);
    const loading = ref(false);
    const smallbanner_loading = ref(false);
    const current = new Date();
    const today = moment(current).format("YYYY-MM-DD");
    const error = ref(null);

    const windowSize = ref(window.innerWidth)
    onMounted(() => {
        window.addEventListener('resize', () => {windowSize.value = window.innerWidth} )
    })

    // customize vue3 carousel component for small banner button and icon
    onUpdated(() => {         
      // $(".carousel__icon" ).attr("viewBox","0 0 24 24");
      $(".carousel__prev").attr("style","background-color:#0069a7;");
      $(".carousel__next").attr("style","background-color:#0069a7;");
      $(".carousel__next" ).children().remove()
      $(".carousel__next" ).append("<i class='bi bi-chevron-right' style='font-size:0.8rem'></i>")
      $(".carousel__prev" ).children().remove()
      $(".carousel__prev" ).append("<i class='bi bi-chevron-left' style='font-size:0.8rem'></i>")
    })

    //get the list of hero banners
    getAPI
      .get(hero_url)
      .then((response) => {
        if (response.status === 200) {
          hero_banner.value = response.data.items;
          let hero_banner_array_before_random = hero_banner.value;
          let hero_banner_array = hero_banner_array_before_random.sort(function(){return 0.5 - Math.random()})
          let i =0
          for (let index in hero_banner_array) {
            if (
              today >= hero_banner_array[index].start_date &&
              today < hero_banner_array[index].end_date
            ) {
              let url = hero_banner_array[index].image.meta.download_url;
              let final_url = url;
              let hero_link = hero_banner_array[index].link;
              hero_src.value[i] = [final_url, hero_link]
              i=i+1
            }
          }       
          // hero_src_display.value = hero_src.value
          //console.log(hero_src.value)
          loading.value = true;
        }
      })
      .catch((err) => {
        error.value = err.response;
      });

    //get the list of small banners
    getAPI
      .get(small_url)
      .then((response) => {
        if (response.status === 200) {
          small_banner.value = response.data.items;
          let small_banner_array_before_random = small_banner.value;
          let small_banner_array = small_banner_array_before_random.sort(function(){return 0.5 - Math.random()})
          for (let index in small_banner_array) {
            if(
              today >= small_banner_array[index].start_date &&
              today < small_banner_array[index].end_date
            ){
              let url_small = small_banner_array[index].image.meta.download_url;
              let final_small_url = url_small;
              let small_link = small_banner_array[index].link
              small_src.value.push([final_small_url,small_link]);
            }  
          }
          smallbanner_loading.value = true
        //     .sort(function() {
        //       return 0.5 - Math.random();
        //     })
        //     .slice(0, 4);
        }
        //console.log(small_src_display.value)
      })
      .catch((err) => {
        error.value = err.response;
      });


    //get the list of featured stores
    getAPI
      .get(featured_store_url)
      .then((response) => {
        featured_store.value = response.data.items;
      })
      .catch((err) => {
        error.value = err;
      });

    //get the newsletter background image
    getAPI
      .get(footer_url)
      .then((response) => {
        if (response.status === 200) {
          footer_banner.value = response.data.items;
          let footer_banner_array = footer_banner.value;
          for (let index in footer_banner_array) {
            let url_footer = footer_banner_array[index].image.meta.download_url;
            let final_footer_url = url_footer;
            footer_src.value.push(final_footer_url);
          }
        }
      })
      .catch((err) => {
        error.value = err;
      });

    //get the reseller background image
    getAPI
      .get(reseller_url)
      .then((response) => {
        reseller_src.value = response.data.items[0].image.meta.download_url;
      })
      .catch((err) => {
        error.value = err;
      });

    return {
      hero_src,
      small_src,
      footer_src,
      reseller_src,
      featured_store,
      loading,
      smallbanner_loading,
      windowSize,
      error
    };
  },
};
</script>

<style scoped>
.card {
  background: none;
  border: none;
}

.block {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.sm_banner{
  padding: 40px 40px;
}
.sm_banner_layout{
  margin:0 10px;
}
.carousel__prev, .carousel__next{
  background-color: #4CAF50 !important;
}


/* button >>>.carousel__prev{
  background-color: #4CAF50;
} */
/* button.carousel__next {
  background-color: #0069a7;
} */

/* #small_banner {
  display: table;
  width: 100%;
  padding: 60px 20px;
  margin:0 auto;

} */
/* #small_banner img {
  display: table;
  margin: 40px auto;
  max-width: 100%;
} */
/* #small_banner div {
 width:50%;
} */
/* @media (min-width: 922px) {
  .small_banner img {
    float: left;
    width: calc(25% - 40px);
    margin: 20px;
    max-height: 400px;
    max-width: 500px;
  }
} */

#footer_banner {
  width: 100%;
}
#footer_banner img {
  max-width: 100%;
}

#reseller {
  padding-top: 40px;
  padding-bottom: 80px;
  background: url("/img/background.520a2517.jpg") no-repeat;
  background-size: cover;
}
#reseller h1 {
  margin-top: 20px;
  color: #fff;
  font-size: 36px !important;
}
#reseller_cards {
  display: table;
  margin: 0 auto;
}
@media (min-width: 992px) {
  #reseller_cards .flex {
    display: flex;
  }
}

#reseller .card {
  float: left;
  padding: 30px;
  font-family: Segoe Semilight;
  border-radius: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0.9;
}
#reseller .card h2 {
  font-size: 36px !important;
}
@media (max-width: 991px) {
  #reseller .card {
    width: 100%;
    margin: 20px 0;
  }
}
@media (min-width: 992px) {
  #reseller .card {
    margin: 0 20px;
    width: calc(33.33% - 40px);
    max-width: 350px;
  }
}

#reseller .card h2 {
  display: flex;
  max-width: 200px;
  height: 70px;
  margin: 0 auto;
  align-items: center;
  color: #003855;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
#reseller .card p {
  margin: 20px 0;
  color: #000;
  font-family: Roboto Medium;
  font-size: 16px;
}
#reseller_cards .card a {
  color: #dd0000;
  font-size: 18x;
  text-decoration: underline;
}
#reseller_cards .card a:hover {
  color: #dd0000 !important;
}

#reseller a.btn {
  clear: both;
  display: table;
  position: relative;
  top: 40px;

  width: 100%;
  max-width: 260px;

  margin: 0 auto;
  padding: 15px 30px;

  color: #fff;
  font-family: Roboto Bold;
  font-size: 16px;
  /* text-transform: uppercase; */

  border-radius: 0;
  background: #a90000;
}
#reseller a.btn:hover {
  color: #fff;
  background: #ff0000;
}

#featured_stores {
  display: table;
  text-align: center;
  margin: 0 auto;
}
#featured_stores {
  font-size:34px;
}
#featured_stores h1 {
  font-size: 34px !important;
  font-family:Roboto;
  color:#5a5a5a;
}
#featured_stores div {
  padding-left: 10%;
}
#featured_stores > div > div {
  margin: 100px auto;
  max-width: 252px;
}
#featured_stores a {
  display: inline-block;
  margin: 10px 0;
}
#featured_stores img {
  float: left;
  max-width: 100%;
  max-height: 91px;
  background: #fff;
}
@media (min-width: 691px) {
  #featured_stores img {
    margin: 10px 20px;
  }
}
@media (min-width: 691px) and (max-width: 1288px) {
  #featured_stores > div > div {
    max-width: 600px;
  }
}
@media (min-width: 1475px) {
  #featured_stores > div > div {
    display: table;
  }
}
@media (min-width: 1289px) {
  #featured_stores.section > div > div {
    max-width: 1391px;
    margin-left: 50px;
    padding-left: 10%;
  }
  #featured_stores.section a {
    display: inline-block;
    width: 25%;
  }
}

#newsletter {
  clear: both;
  padding: 100px 40px 140px;
  background: url("/img/Group_32.7f518062.png") no-repeat;
  background-size: cover;
}
#newsletter h1 {
  display: table;
  margin: 0 auto;
  color: #fff;
  font-size: 55px;
  font-family: Roboto Bold;
}
#newsletter p {
  display: table;
  margin: 20px auto;
  color: #fff;
  font-size: 29px;
  font-family: Roboto;
}
#newsletter a.btn {
  clear: both;
  display: table;
  position: relative;
  top: 40px;

  width: 100%;
  max-width: 260px;

  margin: 0 auto;
  padding: 15px 30px;

  color: #fff;
  font-family: Roboto; /*Roboto;*/
  font-size: 21px;

  border-radius: 0;
  background: #0069a7; /*#004175;*/
}
#newsletter a.btn:hover {
  color: #fff;
  background: #004175;
}

#eblast {
  padding: 20px 50px;
  text-align: center;
  background: #333;
}
#eblast span {
  margin: 0 20px;
  color: #ffdc73;
  font-family: Roboto Medium;
  font-size: 21px;
}
#eblast button {
  margin: 0 auto;
  padding: 5px 20px;

  color: #fff;
  font-family: Roboto Medium;
  font-size: 16px;

  border: 1px solid #fff;
  border-radius: 0;
  background: none;
}

#promotions {
  display: table;
  width: 100%;
  padding-bottom: 60px;
  background: #ebebeb;
}

#promotions h1 > i {
  position: relative;
  top: -1px;
}

#promotions .card {
  float: left;
  display: table;
  width: 100%;
  font-size: 21px;
  text-align: center;
}
@media (max-width: 991px) {
  #promotions button {
    width: 233px;
  }
  #promotions .card:not(:last-of-type) {
    margin-bottom: 75px;
  }
}
@media (min-width: 992px) {
  #promotions .card {
    width: 33%;
  }
  #promotions .card p {
    padding: 0 20%;
  }
}
#promotions .card img {
  max-height: 50px;
}
#promotions .card h2 {
  margin: 15px 0;
  color: #265197;
  font-family: Roboto Medium;
  font-size: 21px;
}
#promotions .card .btn {
  padding: 10px 20px;

  color: #777;
  font-family: Roboto Medium;
  font-size: 16px;

  background: none;
  border: 2px solid #888;
  border-radius: 0;
}
#promotions .card .btn:hover {
  color: #fff;
  background: #265197;
  border: 2px solid #265197;
}

#history {
  padding: 60px 30px;
  text-align: center;
  font-family: Roboto Medium;
  background: url("/img/company-background.f5f1992b.png");
  background-size: cover;
}
#history p {
  max-width: 800px;
  margin: 0 auto 40px;
  color: #fff;
  font-size: 21px;
}
#history .btn {
  width: 100%;
  max-width: 260px;
  padding: 15px 30px;

  color: #265197;
  font-family: Roboto Bold;
  font-size: 16px;

  background: #fff;
  border-radius: 0;
}
#history .btn:hover {
  background: #265197;
  color: #fff;
}
.btn_round {
  border-radius: 8px !important;
}
</style>
